var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"table-border",attrs:{"dense":"","items":_vm.items,"headers":_vm.headerTasks,"hide-default-header":"","sort-by":"fecha","sort-desc":true,"footer-props":{itemsPerPageText: 'Mostrar'},"loading":_vm.loading,"mobile-breakpoint":"10"},on:{"click:row":_vm.viewTaskLog},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-chip',{staticClass:"font-weight-medium chip-status",attrs:{"small":"","label":"","dark":"","color":item.status},domProps:{"textContent":_vm._s( item.codigo)}})],1)]}},{key:"item.mensaje",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex py-4",class:{'flex-column':_vm.$vuetify.breakpoint.xsOnly}},[_c('span',{staticClass:"text-body info--text text--darken-1 font-weight-regular ml-4",domProps:{"textContent":_vm._s(item.usuario)}}),_c('span',{staticClass:"text-body primary--text font-weight-regular ml-4",domProps:{"textContent":_vm._s(item.mensaje)}})])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex error-log"},[_c('span',{staticClass:"deep-purple--text text--darken-4 font-weight-regular"},[_vm._v(" "+_vm._s(_vm._f("time")(item.fecha))+" ")])])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"18","color":item.status},domProps:{"textContent":_vm._s( item.status === 'success' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close-octagon-outline' )}})]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center pa-4"},[_c('v-icon',{attrs:{"size":"20","color":"yellow"}},[_vm._v("mdi-information-outline")]),_c('span',{staticClass:"blue-grey--text font-italic ml-2"},[_vm._v("No se encontraron Tareas ejecutadas recientemente.")])],1)]},proxy:true}])}),_c('descripcion-log',{attrs:{"log":_vm.logTask},model:{value:(_vm.viewLog),callback:function ($$v) {_vm.viewLog=$$v},expression:"viewLog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }