<template>
<div>
  <v-data-table
    dense
    :items="items"
    :headers="headerTasks"
    hide-default-header
    class="table-border"
    sort-by="fecha"
    :sort-desc="true"
    :footer-props="{itemsPerPageText: 'Mostrar'}"
    :loading="loading"
    mobile-breakpoint="10"
    @click:row="viewTaskLog"
  >
    <template v-slot:item.codigo="{item}">
        <div class="d-flex justify-end">
        <v-chip
          small
          label
          dark
          class="font-weight-medium chip-status"
          :color="item.status"
          v-text=" item.codigo"
        />
        </div>
    </template>
    <template v-slot:item.mensaje="{item}">
      <div
        class="d-flex py-4"
        :class="{'flex-column':$vuetify.breakpoint.xsOnly}"
      >
        <span
          class="text-body info--text text--darken-1 font-weight-regular ml-4"
          v-text="item.usuario"
        />
        <span
          class="text-body primary--text font-weight-regular ml-4"
          v-text="item.mensaje"
        />
      </div>
    </template>
    <template v-slot:item.fecha="{item}">
      <div class="d-flex error-log">
        <!-- <v-icon
          size="18"
          :color="item.status"
          v-text=" item.status === 'success' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close-octagon-outline' "
        /> -->
        <span class="deep-purple--text text--darken-4 font-weight-regular">
          {{ item.fecha | time }}
        </span>
      </div>
    </template>
    <template v-slot:item.icon="{item}">
      <v-icon
        size="18"
        :color="item.status"
        v-text=" item.status === 'success' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close-octagon-outline' "
      />
    </template>
    <template v-slot:no-data>
      <div class="d-flex justify-center align-center pa-4">
        <v-icon size="20" color="yellow">mdi-information-outline</v-icon>
        <span class="blue-grey--text font-italic ml-2">No se encontraron Tareas ejecutadas recientemente.</span>
      </div>
    </template>
  </v-data-table>
  <descripcion-log v-model="viewLog" :log="logTask" />
</div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'LogsTable',
  components: {
    DescripcionLog: () => import(
      /* webpackChunkName: "descripcion-log" */
      '@/views/Administracion/Components/DescripcionLog'
    ),
  },
  filters: {
    time (val) {
      return moment(val).format('D MMM YYYY, hh:mm:ss A')
    }
  },
  props: {
    items: {
      type: Array,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    viewLog: false,
    logTask: {},
  }),
  methods: {
    viewTaskLog (item) {
      this.logTask = { ...item }
      this.viewLog = true
    },
  },
  computed: {
    headerTasks () {
      return [
        { text: '', value: 'icon', width: 10, align: ' item-icon-log' },
        { text: '', value: 'fecha', width: 30, align: ' item-date-log' },
        { text: '', value: 'mensaje', align: ' pl-0' },
        { text: '', value: 'codigo', align: this.$vuetify.breakpoint.xs ? ' d-none' : '' },
      ]
    },
  }

}
</script>
<style>
.table-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.chip-status {
  width: 40px;
  justify-content: center;
  padding: 6px 0;
}
.item-date-log {
  text-align: start;
  width: 200px !important;
  padding: 0 !important;
}
.item-icon-log {
  text-align: center;
  width: 30px !important;
  padding: 0 !important;
}

/*.table-border > .v-data-table__wrapper > table > tbody > tr > td:has(div) {
  background: #000 !important;
}*/

.table-border > .v-data-table__wrapper > table > tbody > tr {
  cursor: pointer !important;
}
</style>
